const Theme = {
  color: {
    accent: '#845cfb',

    primary: '#ee0077',

    white: '#ffffff',

    black: '#0a0a0a',
    blackAlphaHover: 'rgba(255, 10, 10, 0.1)'
  },
  gradient: 'linear-gradient(86.2deg, #845cfb -4.88%, #ee0077 95.26%)',
  breakpoint: {
    large: '1024px',
    medium: '769px',
    small: '480px'
  }
};

export default Theme;
