import React, { useEffect, useRef, useState } from 'react';
import { Container, Wrap } from '../others.style';
import {
  LogoWrap,
  Logo,
  NavLinks,
  NavLink,
  MobileNavLinks,
  MenuWrap,
  MenuIcon
} from './nav-bar.style';

import logo from '../../assets/logo_with_text.webp';
import hamburger from '../../assets/menu.svg';
import { useOnClickOutside } from '../../hooks/useOnClickOutside.hook';

const NavBar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 110) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);

    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const clickOutRef = useRef(null);
  useOnClickOutside(clickOutRef, () => setShowMobileNav(false));

  const links = [
    {
      name: 'Home',
      url: 'home',
      offset: 200,
      mobileOffset: 100
    },
    {
      name: 'Key Features',
      url: 'features',
      offset: 108,
      mobileOffset: 80
    },
    {
      name: 'FAQs',
      url: 'faqs',
      offset: 108,
      mobileOffset: 80
    },
    {
      name: 'Join Our Community',
      url: 'join-us',
      offset: 128,
      mobileOffset: 80
    }
  ];

  return (
    <Container
      bg={props => props.theme.color.white}
      margin="0 0 44px 0"
      position="sticky"
      top="0"
      zIndex="3"
      scrollNav={scrollNav}
    >
      <Wrap justify="space-between">
        <LogoWrap
          to="home"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-200}
          tabIndex={0}
          aria-label="logo"
        >
          <Logo src={logo} alt="website logo" />
        </LogoWrap>

        <NavLinks>
          {links?.map(({ name, url, offset }, i) => {
            return (
              <NavLink
                key={i}
                to={url}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-offset}
                tabIndex={0}
                aria-label={name}
              >
                {name}
              </NavLink>
            );
          })}
        </NavLinks>
        <MenuWrap ref={clickOutRef}>
          <MenuIcon
            src={hamburger}
            alt="menu_icon"
            onClick={() => setShowMobileNav(!showMobileNav)}
          />
          <MobileNavLinks show={showMobileNav}>
            {links?.map(({ name, url, mobileOffset }, i) => {
              return (
                <NavLink
                  key={i}
                  to={url}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-mobileOffset}
                  onClick={() => setShowMobileNav(false)}
                >
                  {name}
                </NavLink>
              );
            })}
          </MobileNavLinks>
        </MenuWrap>
      </Wrap>
    </Container>
  );
};

export default NavBar;
