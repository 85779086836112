import { Link } from 'react-scroll';
import styled from 'styled-components';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const Left = styled.div`
  flex: 1;

  @media screen and (max-width: ${midBp}) {
    padding: 0 60px;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: 0 40px;
  }
`;

export const HeroImgWrap = styled.div`
  max-width: 528px;
  width: 100%;
  margin-top: -128px;

  @media screen and (max-width: ${largeBp}) {
    margin-top: -64px;
  }

  @media screen and (max-width: ${midBp}) {
    margin-top: 0;
  }
`;

export const HeroImg = styled.img``;

export const Right = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 120px 24px;

  font-family: 'Nexa Bold', sans-serif;

  @media screen and (max-width: ${midBp}) {
    padding: 50px 0;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: 20px 0;
  }
`;

export const TextWrap = styled.div`
  color: ${props => props.theme.color.white};
  position: relative;

  width: 575px;
  margin-bottom: 24px;

  @media screen and (max-width: ${largeBp}) {
    width: unset;
    margin-bottom: 0;
  }
`;

export const Heading2 = styled.h2`
  position: absolute;
  right: 42px;
  top: -10px;

  font-size: 3.0625em;
  line-height: 40px;
  letter-spacing: -0.04em;

  @media screen and (max-width: ${largeBp}) {
    right: 8px;
    top: -4px;

    font-size: 2em;
    line-height: unset;
  }

  @media screen and (max-width: ${smallBp}) {
    right: 8px;
    top: 24px;

    font-size: 1.3125em;
    line-height: unset;
  }
`;

export const Heading1 = styled.h1`
  font-size: 12.625em;
  line-height: 164px;
  letter-spacing: -0.04em;

  @media screen and (max-width: ${largeBp}) {
    font-size: 9em;
    line-height: 130px;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 6em;
    line-height: unset;
  }
`;

export const HeadingOutline = styled.h1`
  margin-top: -100px;

  font-size: 12.625em;
  line-height: 164px;
  letter-spacing: -0.04em;

  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.37);

  @media screen and (max-width: ${largeBp}) {
    margin-top: -114px;
    font-size: 9em;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 6em;
    line-height: unset;
  }
`;

export const Button = styled(Link)`
  background: ${props => props.theme.color.white};

  display: flex;
  justify-content: center;

  padding: 18px 164px;
  border-radius: 30px;
  border: none;
  box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.13);

  font-size: 2.125em;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  cursor: pointer;
  transition: 200ms ease-in-out;

  &:hover {
    background: #ececec;
  }

  @media screen and (max-width: ${largeBp}) {
    padding: 14px 80px;

    font-size: 1.75em;
    line-height: unset;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: 12px 60px;

    font-size: 1.25em;
    line-height: unset;
  }
`;

export const AppStoreBtns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 28px;

  @media screen and (max-width: ${largeBp}) {
    max-width: 320px;
    width: 100%;
  }

  @media screen and (max-width: ${smallBp}) {
    max-width: 240px;
    width: 100%;
  }
`;

export const PlayStoreLink = styled.a`
  width: 184px;
  height: auto;
  margin-right: 20px;

  @media screen and (max-width: ${midBp}) {
    margin-right: 10px;
  }
`;

export const PlayStore = styled.img``;

export const AppleStoreLink = styled.a`
  width: 184px;
  height: auto;
  margin-left: 20px;

  @media screen and (max-width: ${midBp}) {
    margin-left: 10px;
  }
`;

export const AppleStore = styled.img``;
