import React from 'react';
import {
  BoldSpan,
  CommonHeading,
  Container,
  GradientBar,
  Wrap
} from '../others.style';
import {
  FooterP,
  FooterLink,
  SocialsWrap,
  Socials,
  SocialLink,
  SocialIcon
} from './footer.style';

import twitter from '../../assets/twitter.svg';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import linkedin from '../../assets/linkedin.svg';

const Footer = () => {
  const socialsData = [
    {
      url: 'https://m.facebook.com/100089259922154/',
      icon: facebook,
      altText: 'facebook icon',
      ariaLabel: 'link to facebook page'
    },
    {
      url: 'https://www.instagram.com/useclik',
      icon: instagram,
      altText: 'instagram icon',
      ariaLabel: 'link to instagram page'
    },
    {
      url: 'https://www.twitter.com/useclik',
      icon: twitter,
      altText: 'twitter icon',
      ariaLabel: 'link to twitter page'
    },
    {
      url: 'https://www.linkedin.com/company/clik-app/',
      icon: linkedin,
      altText: 'linkedin icon',
      ariaLabel: 'link to linkedin page'
    }
  ];

  return (
    <Container margin="90px 0 0 0" mdBpMargin="50px 0 0 0">
      <Wrap flexDir="column">
        <CommonHeading margin="0 0 12px 0">Contact Us</CommonHeading>
        <FooterP>
          Got some questions or ideas for us? Reach out to us at{' '}
          <FooterLink
            href="mailto:info@useclik.com"
            aria-label="contact support mail"
          >
            <BoldSpan>info@useclik.com</BoldSpan>
          </FooterLink>
        </FooterP>
        <SocialsWrap>
          <FooterP fontSize="1.125em" smBpFontSize="0.75em">
            Follow us
          </FooterP>
          <Socials>
            {socialsData?.map(({ url, icon, altText, ariaLabel }, index) => {
              return (
                <SocialLink
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={ariaLabel}
                >
                  <SocialIcon src={icon} alt={altText} />
                </SocialLink>
              );
            })}
          </Socials>
        </SocialsWrap>
      </Wrap>
      <Wrap maxWidth="unset" padding="46px 0 12px 0" smBpPadding="20px 0 8px 0">
        <GradientBar height="35px" />
      </Wrap>
    </Container>
  );
};

export default Footer;
