import styled from 'styled-components';
import { Link } from 'react-scroll';

const primary = props => props.theme.color.primary;

const midBp = props => props.theme.breakpoint.medium;

export const LogoWrap = styled(Link)`
  width: 158px;
  height: auto;
  margin-right: 100px;

  cursor: pointer;

  @media screen and (max-width: ${midBp}) {
    width: 74px;
  }
`;

export const Logo = styled.img``;

export const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 564px;
  width: 100%;

  font-size: 22px;

  @media screen and (max-width: ${midBp}) {
    display: none;
  }
`;

export const MenuWrap = styled.div`
  position: relative;

  display: none;

  width: 32px;
  cursor: pointer;

  @media screen and (max-width: ${midBp}) {
    display: flex;
  }
`;

export const MenuIcon = styled.img`
  width: 100%;
`;

export const MobileNavLinks = styled.div`
  position: absolute;
  top: 32px;
  right: 0;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;

  padding: 20px;
  box-shadow: -1px 0px 7px 1px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  @media screen and (max-width: ${midBp}) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
  }
`;

export const NavLink = styled(Link)`
  font-weight: 500;
  letter-spacing: -0.01em;
  text-align: center;

  transition: ease-in-out 200ms;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }

  &.active {
    color: ${primary};
  }

  @media screen and (max-width: ${midBp}) {
    margin: 8px;

    &.active {
      color: inherit;
    }
  }
`;
