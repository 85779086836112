import React, { useEffect, useRef, useState } from 'react';
import { CommonHeading, Container, Wrap } from '../others.style';
import {
  FaqsContainer,
  FaqItem,
  FaqHeading,
  FaQuestion,
  ExpandIcon,
  FaqContent
} from './faqs-style';
import arrow from '../../assets/caret_down.svg';

const Faq = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const answerRef = useRef(null);

  useEffect(() => {
    answerRef.current.innerHTML = answer;
  }, [answer]);

  return (
    <FaqItem>
      <FaqHeading onClick={() => setIsOpen(!isOpen)}>
        <FaQuestion>{question}</FaQuestion>

        <ExpandIcon src={arrow} alt="expand icon for FAQ" isOpen={isOpen} />
      </FaqHeading>
      <FaqContent isOpen={isOpen} ref={answerRef}></FaqContent>
    </FaqItem>
  );
};

const Faqs = () => {
  const questionsAndAnswers = [
    {
      question: 'What is Clik?',
      answer: `Clik is a private social media app that allows users to connect with their friends and family in a secure and personalized way.`
    },
    {
      question: 'Is Clik free to use?',
      answer: `Yes, Clik is completely free to use.`
    },
    {
      question: 'Is my data secure on Clik?',
      answer: `Yes, Clik takes data security and privacy very seriously. We use state-of-the-art encryption and follow industry best practices to keep your data safe.`
    },
    {
      question: 'How is Clik different from other social media apps?',
      answer: `Clik is designed to foster authentic connections without the noise of traditional social media. We prioritize privacy and user control, and don't show ads or promote unsolicited content.`
    },
    {
      question: 'Can I share posts on Clik with people outside of the app?',
      answer: `No, Clik is a closed social network. You can only share content with other users who have also downloaded the app.`
    },
    {
      question: 'How do I report inappropriate content or behavior on Clik?',
      answer: `You can report inappropriate content or behavior by messaging us directly at <a href="mailto:info@useclik.com">info@useclik.com</a>. We take user safety very seriously and will investigate any reports promptly.`
    },
    {
      question: 'Is there a limit on the number of friends I can have on Clik?',
      answer: `No, there is no limit on the number of friends you can have on Clik. However, we encourage users to keep their network small and meaningful to foster deeper connections.`
    },
    {
      question: 'Can I suggest new features for the app?',
      answer: `Absolutely! We welcome feedback and suggestions from our users on how to make Clik even better. You can send your feature requests or ideas to us at <a href="mailto:info@useclik.com">info@useclik.com</a>. While we can't guarantee that we will implement every suggestion, we value your input and will consider all ideas carefully. Thank you for helping us improve Clik!`
    }
  ];

  return (
    <Container id="faqs">
      <Wrap flexDir="column">
        <CommonHeading
          margin="76px 0 72px 0"
          lgBpMargin="76px 0 72px 0"
          mdBpMargin="40px 0 36px 0"
          smBpMargin="0 0 20px 0"
        >
          FAQs
        </CommonHeading>

        <FaqsContainer>
          {questionsAndAnswers.map((item, index) => (
            <Faq key={index} question={item.question} answer={item.answer} />
          ))}
        </FaqsContainer>
      </Wrap>
    </Container>
  );
};

export default Faqs;
