import React from 'react';
import {
  BoldSpan,
  CommonHeading,
  Container,
  GradientBar,
  Wrap
} from '../others.style';
import {
  Content,
  Left,
  SectionHeader,
  IconWrap,
  Icon,
  ContentP,
  Right,
  FeatureWrap,
  FeatureTitle,
  FeatureImg,
  FeatureDescription
} from './features.style';

import no_ads from '../../assets/no_ads.webp';
import lock from '../../assets/lock.svg';
import question_bank from '../../assets/question_ank_categories.webp';
import profile from '../../assets/profile.webp';
import socials from '../../assets/social_media.webp';

const Features = () => {
  const primary = props => props.theme.color.primary;

  const Feature = ({ title, image, desc, first, last }) => (
    <FeatureWrap first={first} last={last}>
      <FeatureTitle>{title || 'Feature'}</FeatureTitle>
      {image ? <FeatureImg src={image} alt="" /> : ''}
      <FeatureDescription>{desc || 'Description'}</FeatureDescription>
    </FeatureWrap>
  );

  return (
    <Container id="features">
      <GradientBar margin="16px 0 0 0" />
      <Wrap flexDir="column">
        <CommonHeading
          margin="76px 0 72px 0"
          lgBpMargin="76px 0 72px 0"
          mdBpMargin="40px 0 36px 0"
          smBpMargin="0 0 20px 0"
        >
          Key Features
        </CommonHeading>

        <Content>
          <Left>
            <SectionHeader alwaysOn>
              <IconWrap padding="21px" lgBpPadding="10px" mdBpPadding="8px">
                <Icon src={lock} alt="" />
              </IconWrap>
              <ContentP>
                <BoldSpan fg={primary}>
                  Your data and privacy is our priority -{' '}
                </BoldSpan>
                Every content you share on our platform is safe and only
                accessible to you and those you choose to share it with. Your
                chats are also end-to-end encrypted, ensuring that no one
                outside of your conversations can read them, not even us. We
                never use or store your personal information, meaning it cannot
                be sold to marketing or advertising companies
              </ContentP>
            </SectionHeader>
            <SectionHeader mobileOnly>
              <IconWrap>
                <Icon src={no_ads} alt="" />
              </IconWrap>
              <ContentP>
                <BoldSpan fg={primary}> No Ads on the platform - </BoldSpan>
                Clik is a closed platform free from external interference,
                including ads. This means you can enjoy an ad-free experience,
                free from the distractions and intrusions of targeted ads and
                suggested pages and profiles
              </ContentP>
            </SectionHeader>

            <Feature
              title="Question Bank"
              image={question_bank}
              desc="We believe that getting to know each other is an important aspect 
              of building relationships. That's why we have curated a selection of 
              engaging questions covering various aspects of personal life and career, 
              giving group members the opportunity to discover more about each other. 
              As the owner of your content, you have the power to choose which members 
              have access to your answers. This allows you to control the level of 
              personal information you share, while still fostering meaningful connections 
              with others"
              first
            />

            <Feature
              title="Memory Organizer"
              image={profile}
              desc="Clik organizes your memories by days, months, and years, making it 
              easy to access and reminisce about past experiences. And with a visually 
              pleasing interface, you can enjoy revisiting your memories even more"
            />
          </Left>
          <Right>
            <SectionHeader desktopOnly>
              <IconWrap>
                <Icon src={no_ads} alt="" />
              </IconWrap>
              <ContentP>
                <BoldSpan fg={primary}> No Ads on the platform - </BoldSpan>
                Clik is a closed platform free from external interference,
                including ads. This means you can enjoy an ad-free experience,
                free from the distractions and intrusions of targeted ads and
                suggested pages and profiles
              </ContentP>
            </SectionHeader>
            <Feature
              title="Your Clik, Your Choice"
              desc={` We have implemented a strict policy of "No Invitation, 
              No Entry." This means that you are in full control of the
              people and groups that can access your personal information
              and pictures.`}
            />
            <Feature
              title="Filtered Social media"
              image={socials}
              desc={`Clik provides an alternative route to accessing traditional 
              social media platforms, enabling you to curate the content you wish
              to view. This allows you to reap the benefits of social media without
              being subject to distractions and disruptions.`}
            />
            <Feature
              title="Increased authenticity"
              desc="Without the pressure to present a perfect image to the world,
              Clik helps you feel more comfortable being yourself and sharing more 
              authentic content"
              last
            />
          </Right>
        </Content>
      </Wrap>
    </Container>
  );
};

export default Features;
