import GlobalStyle from './global.style';
import GlobalFonts from './fonts/fonts';
import { ThemeProvider } from 'styled-components';
import Theme from './theme';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import About from './components/About';
import Pointers from './components/Pointers';
import Promotional from './components/Promotional';
import Features from './components/Features';
import Newsletter from './components/Newsletter';
import Footer from './components/Footer';
import Faqs from './components/Faqs';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <GlobalStyle />
        <GlobalFonts />

        <NavBar />
        <Hero />
        <About />
        <Pointers />
        <Promotional />
        <Features />
        <Faqs />
        <Newsletter />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
