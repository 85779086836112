import styled from 'styled-components';

const smallBp = props => props.theme.breakpoint.small;

export const FaqsContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const FaqItem = styled.div`
  border-top: 1px solid #00000055;
  border-top: 1px solid black;
`;

export const FaqHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  padding: 2rem 0;
  transition: 100ms ease-in-out;
`;

export const FaQuestion = styled.h4`
  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }
`;

export const ExpandIcon = styled.img`
  margin: 10px 0 10px 20px;

  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 200ms ease-in-out;
`;

export const FaqContent = styled.p`
  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }

  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  padding: ${({ isOpen }) => (isOpen ? '0 2rem 2rem 2rem' : '0')};

  transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;

  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};

  transition: 100ms ease-in-out;

  a {
    color: ${props => props.theme.color.primary};
  }

  @media screen and (max-width: ${smallBp}) {
    padding: ${({ isOpen }) => (isOpen ? '0 0 2rem 0' : '0')};
  }
`;
