import React from 'react';

import { BoldSpan, Container, Wrap } from '../others.style';
import {
  Top,
  Left,
  IphonesImgWrap,
  IphonesImg,
  ChildImage,
  Right,
  TextWrap,
  Heading2,
  Heading1,
  Bottom,
  ChatBubblesImgWrap,
  ChatBubblesImg,
  Heading3,
  ContentWrap,
  PromotionalP
} from './promotional.style';

import iPhones from '../../assets/iphones2.webp';
import messages from '../../assets/message_bubbles.webp';
import postStat from '../../assets/post_stat.webp';

const Promotional = () => {
  const primary = props => props.theme.color.primary;

  return (
    <>
      <Container bg={primary}>
        <Wrap flexDir="column" paddingY="86px">
          <Top>
            <Left>
              <IphonesImgWrap>
                <IphonesImg
                  src={iPhones}
                  alt="iPhones showing app user interface"
                />
                <ChildImage src={postStat} alt="" />
              </IphonesImgWrap>
            </Left>
            <Right>
              <TextWrap>
                <Heading2>Clik is for</Heading2>
                <Heading1>Everyone</Heading1>
                <Heading3>Yes, you in particular!</Heading3>
              </TextWrap>
            </Right>
          </Top>
          <Bottom>
            <ChatBubblesImgWrap>
              <ChatBubblesImg
                src={messages}
                alt="messages showing user thoughts"
              />
            </ChatBubblesImgWrap>
          </Bottom>
        </Wrap>
      </Container>
      <Container
        bg={`linear-gradient(
          352.88deg, 
          rgba(238, 0, 119, 0.2)
          19.59%,
          rgba(132, 92, 251, 0)
          73.61%, rgba(132, 92, 251, 0)
          91.75%)`}
      >
        <Wrap>
          <ContentWrap>
            <PromotionalP>
              With Clik, you are able to{' '}
              <BoldSpan fg={primary}>
                selectively share your thoughts and experiences with a carefully
                curated group
              </BoldSpan>{' '}
              of friends and family, free from the interference of strangers.
            </PromotionalP>
            <PromotionalP>
              Our platform{' '}
              <BoldSpan fg={primary}>gives you complete control</BoldSpan> over
              who has access to your content, allowing you to capture and share
              any memory you deem important without fear of oversharing or
              compromising your privacy.
            </PromotionalP>
            <PromotionalP>
              At Clik, we understand the human need to stay connected to others,
              but we also recognize the importance of protecting our mental
              health. That's why we offer a{' '}
              <BoldSpan fg={primary}>filtered gateway to social media</BoldSpan>{' '}
              , giving you access to only the things that make you feel safe and
              happy
            </PromotionalP>
          </ContentWrap>
        </Wrap>
      </Container>
    </>
  );
};

export default Promotional;
