import styled from 'styled-components';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const Top = styled.div`
  display: flex;

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const IphonesImgWrap = styled.div`
  position: relative;
  /* width: 400px; */

  @media screen and (max-width: ${largeBp}) {
    /* min-width: 400px; */
  }

  @media screen and (max-width: ${midBp}) {
    margin-top: -48px;
    padding: 0 64px;
  }

  @media screen and (max-width: ${smallBp}) {
    margin-top: -36px;
  }
`;

export const IphonesImg = styled.img`
  width: 100%;
`;

export const ChildImage = styled.img`
  position: absolute;
  bottom: -25px;
  left: -32px;

  max-width: 263px;
  width: 100%;

  @media screen and (max-width: ${largeBp}) {
    bottom: -16px;
    left: -20px;

    max-width: 180px;
  }

  @media screen and (max-width: ${midBp}) {
    left: -8px;

    flex-direction: column;

    max-width: 220px;
  }

  @media screen and (max-width: ${smallBp}) {
    max-width: 120px;
    left: 16px;
  }
`;

export const Right = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;

  @media screen and (max-width: ${midBp}) {
    justify-content: flex-end;
  }
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  color: ${props => props.theme.color.white};

  margin: 0 20px;
  margin-top: 80px;

  font-family: 'Nexa Bold';

  @media screen and (max-width: ${smallBp}) {
    margin-top: 30px;
  }
`;

export const Heading2 = styled.h2`
  margin-bottom: -30px;
  margin-right: 12px;

  font-weight: 700;
  font-size: 2.6875em;
  line-height: 43px;
  text-align: right;
  letter-spacing: -0.015em;

  @media screen and (max-width: ${largeBp}) {
    font-size: 2.4em;
  }

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;

    margin-bottom: -20px;
    margin-right: 0;

    font-size: 1.76em;
    line-height: 30px;
  }

  @media screen and (max-width: ${smallBp}) {
    margin-bottom: -8px;

    font-size: 0.875em;
    line-height: 16px;
  }
`;

export const Heading1 = styled.h1`
  font-weight: 700;
  font-size: 6.6875em;
  line-height: 107px;
  letter-spacing: -0.015em;

  @media screen and (max-width: ${largeBp}) {
    font-size: 6em;
  }

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;

    font-size: 4.375em;
    line-height: 70px;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 2.4375em;
    line-height: 38px;
  }
`;

export const Heading3 = styled.h1`
  margin-top: -14px;

  font-family: 'Glacial Indifference';
  font-weight: 400;
  font-size: 3.3125em;
  line-height: 64px;
  letter-spacing: -0.015em;

  @media screen and (max-width: ${largeBp}) {
    font-size: 3em;
  }

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;

    margin-top: -8px;

    font-size: 2.165em;
    line-height: 42px;
  }

  @media screen and (max-width: ${smallBp}) {
    margin-top: -2px;

    font-size: 1.25em;
    line-height: 22px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const ChatBubblesImgWrap = styled.div`
  margin-top: -100px;
  max-width: 969px;
  width: 100%;
  margin-right: -8px;

  @media screen and (max-width: ${largeBp}) {
    margin-top: 20px;
    max-width: 700px;
  }

  @media screen and (max-width: ${midBp}) {
    margin-right: 0;
  }
`;

export const ChatBubblesImg = styled.img`
  width: 100%;
`;

export const ContentWrap = styled.div`
  background-color: ${props => props.theme.color.white};

  margin: 70px 62px 0 62px;
  padding: 18px 93px 54px 93px;
  border-radius: 15px;

  @media screen and (max-width: ${midBp}) {
    margin: 14px 0;
    padding: 12px 40px 32px 40px;
  }

  @media screen and (max-width: ${smallBp}) {
    margin: 14px 0;
    padding: 7px 18px 9px 18px;
  }
`;

export const PromotionalP = styled.p`
  margin-bottom: 32px;

  font-weight: 500;
  line-height: 165.02%;
  letter-spacing: -0.01em;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }
`;
