import styled from 'styled-components';

const black = props => props.theme.color.black;

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const Content = styled.div`
  display: flex;

  width: 100%;

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 100px 40px 0;
  border-right: 1px solid ${black};

  @media screen and (max-width: ${largeBp}) {
    padding: 10px 40px 20px 0;
  }

  @media screen and (max-width: ${midBp}) {
    padding: 0;
    border: 0;
  }
`;

export const SectionHeader = styled.div`
  display: ${({ alwaysOn, mobileOnly }) =>
    alwaysOn ? 'flex' : mobileOnly ? 'none' : 'flex'};
  justify-content: stretch;
  align-items: flex-start;

  margin-bottom: 20px;

  @media screen and (max-width: ${midBp}) {
    display: ${({ alwaysOn, desktopOnly }) =>
      alwaysOn ? 'flex' : desktopOnly ? 'none' : 'flex'};
  }
`;

export const IconWrap = styled.div`
  max-width: 100px;
  width: 100%;
  margin-right: 25px;
  padding: ${props => props.padding || '14px'};
  border-radius: 50%;

  box-shadow: -3px 13px 39px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: ${largeBp}) {
    max-width: 40px;
    margin-right: 20px;
    padding: ${props => props.lgBpPadding || '7px'};
  }

  @media screen and (max-width: ${midBp}) {
    padding: ${props => props.mdBpPadding || '5px'};

    margin-right: 14px;
  }
`;

export const Icon = styled.img`
  width: 100%;
`;

export const ContentP = styled.p`
  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }
`;

export const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 0 10px 100px;

  @media screen and (max-width: ${largeBp}) {
    padding: 10px 0 20px 40px;
  }

  @media screen and (max-width: ${midBp}) {
    padding: 0;
  }
`;

export const FeatureWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 100px;

  @media screen and (max-width: ${midBp}) {
    margin-top: ${props => (props.first ? '48px' : 0)};
    margin-bottom: ${props => (props.last ? 0 : '40px')};
    padding-bottom: ${props => (props.last ? 0 : '40px')};
    border-bottom: 1px solid ${props => (props.last ? 'transparent' : black)};
  }

  @media screen and (max-width: ${smallBp}) {
    margin-top: ${props => (props.first ? '24px' : 0)};
    margin-bottom: ${props => (props.last ? 0 : '24px')};
    padding-bottom: ${props => (props.last ? 0 : '24px')};
  }
`;

export const FeatureTitle = styled.h2`
  color: ${props => props.theme.color.primary};

  font-weight: 700;
  font-size: 2.0625em;
  line-height: 165.02%;
  text-align: center;
  letter-spacing: -0.01em;

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.25em;
  }
`;

export const FeatureImg = styled.img`
  max-width: 329px;
  width: 100%;
  margin: 20px 0;

  filter: drop-shadow(-3px 11px 32px rgba(0, 0, 0, 0.12));
`;

export const FeatureDescription = styled.p`
  text-align: center;

  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }
`;
