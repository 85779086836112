import React from 'react';
import { Container, Wrap } from '../others.style';
import {
  Left,
  HeroImgWrap,
  HeroImg,
  Right,
  TextWrap,
  Heading2,
  Heading1,
  HeadingOutline,
  Button,
  AppStoreBtns,
  PlayStoreLink,
  PlayStore,
  AppleStoreLink,
  AppleStore
} from './hero.style';

import heroImg from '../../assets/iphones.webp';
import playStore from '../../assets/play_store.webp';
import appleStore from '../../assets/apple_store.webp';

const Hero = () => {
  return (
    <Container bg={props => props.theme.gradient} id="home">
      <Wrap smBpFlexDir="column">
        <Left>
          <HeroImgWrap>
            <HeroImg src={heroImg} alt="iPhones showing sign up page" />
          </HeroImgWrap>
        </Left>
        <Right>
          <TextWrap>
            <Heading2>Click with your</Heading2>
            <Heading1>Clique</Heading1>
            <HeadingOutline>Clique</HeadingOutline>
          </TextWrap>
          <Button
            to="join-us"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            tabIndex={0}
            aria-label="get started"
          >
            Get Started
          </Button>
          <AppStoreBtns>
            <PlayStoreLink
              href="https://play.google.com/store/apps/details?id=com.officialcliqapp.clik"
              aria-label="link to playstore download"
              target="_blank"
              rel="noreferrer"
            >
              <PlayStore src={playStore} alt="link to playstore download" />
            </PlayStoreLink>
            <AppleStoreLink
              href="https://apps.apple.com/us/app/clik-create-your-safe-space/id6449166654"
              aria-label="link to apple store download"
              target="_blank"
              rel="noreferrer"
            >
              <AppleStore src={appleStore} alt="link to apple store download" />
            </AppleStoreLink>
          </AppStoreBtns>
        </Right>
      </Wrap>
    </Container>
  );
};

export default Hero;
