import styled from 'styled-components';

const primary = props => props.theme.color.primary;
const white = props => props.theme.color.white;

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const Text = styled.div`
  text-align: center;
`;

export const SubText = styled.p`
  color: #757575;

  font-family: Inter;
  font-size: 1.75em;
  letter-spacing: 0em;
  line-height: 46.21px;

  @media screen and (max-width: ${largeBp}) {
    font-size: 1.5em;
  }

  @media screen and (max-width: ${midBp}) {
    font-size: 1.25em;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1em;
    line-height: unset;
  }
`;

export const FormWrap = styled.div`
  position: relative;

  margin-top: 50px;
`;

export const Form = styled.form`
  position: relative;
  z-index: 2;

  display: flex;
  box-sizing: content-box;

  background: ${white};

  border-radius: 20px;

  @media screen and (max-width: ${midBp}) {
    margin-top: 36px;
  }

  @media screen and (max-width: ${smallBp}) {
    width: 100%;
    margin-top: 27px;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  top: -36px;
  left: 0;
  z-index: 1;

  display: ${({ show }) => (show ? 'block' : 'none')};

  color: #ff0000bb;

  padding: 4px 8px 24px 8px;
  border: 2px solid #ff000066;
  border-radius: 20px 20px 0 0;

  font-size: 1.125rem;
  font-weight: 500;

  @media screen and (max-width: ${midBp}) {
    top: -4px;

    width: 100%;
    border-radius: 16px 16px 0 0;
  }

  @media screen and (max-width: ${smallBp}) {
    top: -4px;

    width: 100%;
    padding: 4px 8px 18px 8px;
    border-radius: 10px 10px 0 0;
    font-size: 0.875rem;
  }
`;

export const InputWrap = styled.div`
  padding: 26px 64px;
  padding-right: 36px;
  border: 2px solid #d9d9d9;
  border-radius: 20px;

  &:focus-within {
    border: 2px solid #000;
  }

  @media screen and (max-width: ${largeBp}) {
    padding: 18px 48px;
    padding-right: 30px;
    border-radius: 16px;
  }

  @media screen and (max-width: ${midBp}) {
    padding: 14px 32px;
    padding-right: 24px;
  }

  @media screen and (max-width: ${smallBp}) {
    width: 100%;
    padding: 10px 18px;
    padding-right: 18px;
    border-radius: 10px;
  }
`;

export const Input = styled.input`
  width: 420px;
  border: none;
  outline: none;

  font-family: 'Glacial Indifference';
  font-size: 2em;
  line-height: 47px;
  letter-spacing: 0em;

  @media screen and (max-width: ${largeBp}) {
    width: 346px;

    font-size: 1.5em;
  }

  @media screen and (max-width: ${midBp}) {
    width: 272px;

    font-size: 1.25em;
  }

  @media screen and (max-width: ${smallBp}) {
    width: 100%;

    font-size: 1em;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${primary};
  color: ${white};

  margin-left: -36px;
  padding: 30px 100px;
  border-radius: 20px;
  border: none;

  font-family: Montserrat;
  font-size: 1.625em;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;

  transition: 0.3s linear;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.accent};
  }

  &:active {
    background: ${props => props.theme.color.primary};
  }

  @media screen and (max-width: ${largeBp}) {
    margin-left: -30px;
    padding: 22px 80px;
    border-radius: 16px;

    font-size: 1em;
  }

  @media screen and (max-width: ${midBp}) {
    margin-left: -24px;
    padding: 16px 60px;
    border-radius: 13px;

    font-size: 1em;
  }

  @media screen and (max-width: ${smallBp}) {
    margin-left: -18px;
    padding: 10px 40px;
    border-radius: 10px;

    font-size: 1em;
  }
`;

export const Loader = styled.span`
  width: 36px;
  height: 36px;

  margin: 0 21.07px;
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid ${white};
  border-right: 5px solid transparent;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: ${largeBp}) {
    margin: 0 6px;
  }
`;
