import React from 'react';
import { BoldSpan, Container, GradientBar, Wrap } from '../others.style';
import {
  LogoContainer,
  LogoWrap,
  Logo,
  TextWrap,
  Heading,
  AboutP
} from './about.style';

import logo from '../../assets/logo.svg';

const About = () => {
  return (
    <Container>
      <Wrap
        paddingY="88px"
        align="stretch"
        lgBpFlexDir="column"
        smBpFlexDir="column"
      >
        <LogoContainer>
          <LogoWrap>
            <Logo src={logo} alt="website_logo" />
          </LogoWrap>
          <Heading>What is Clik?</Heading>
        </LogoContainer>
        <TextWrap>
          <AboutP>
            Welcome to Clik, the platform that helps you{' '}
            <BoldSpan>
              connect with the people you care about in a private and personal
              way
            </BoldSpan>
            . We know that traditional social media can be overwhelming, with
            strangers having access to your content and a never-ending stream of
            ads and suggested pages. That's why we've created{' '}
            <BoldSpan>
              a space where you can selectively share your life and experiences
            </BoldSpan>{' '}
            with a carefully curated group of friends and family.
          </AboutP>
          <AboutP>
            With Clik, you can create your own "Cliques" of trusted individuals
            and invite only those you choose to join. And when you post pictures
            and videos,{' '}
            <BoldSpan>
              you have the control to decide which Cliques to share them with
            </BoldSpan>
            . This means you can enjoy social media without the distractions and
            interference of outsiders.
          </AboutP>
          <AboutP>
            We hope you'll join us in redefining social media as a place for
            genuine connections and meaningful interactions. Thank you for
            choosing Clik.
          </AboutP>
        </TextWrap>
      </Wrap>
      <GradientBar />
    </Container>
  );
};

export default About;
