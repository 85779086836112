import { createGlobalStyle } from 'styled-components';

import GlacialIndifferenceRegular from './GlacialIndifferenceRegular.otf';
import NexaBold from './NexaBold.otf';

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Glacial Indifference';
        src: local('Glacial Indifference'), local('GlacialIndifference'),
        url(${GlacialIndifferenceRegular}) format('opentype');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'Nexa Bold';
        src: local('Nexa Bold'), local('NexaBold'),
        url(${NexaBold}) format('opentype');
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
    }
`;

export default GlobalFonts;
