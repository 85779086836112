import styled from 'styled-components';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;

export const FooterP = styled.p`
  font-size: ${props => props.fontSize || '1.25em'};
  line-height: 165.02%;
  text-align: center;
  letter-spacing: 0.09em;

  @media screen and (max-width: ${largeBp}) {
  }

  @media screen and (max-width: ${midBp}) {
    font-size: ${props => props.smBpFontSize || '0.875em'};
  }
`;

export const FooterLink = styled.a`
  color: inherit;

  text-decoration: none;
`;

export const SocialsWrap = styled.div`
  display: flex;
  align-items: center;

  margin-top: 28px;
`;

export const Socials = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  margin: 0 14px;
`;

export const SocialIcon = styled.img``;
