import styled from 'styled-components';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const Container = styled.div`
  position: ${props => props.position || ''};
  top: ${props => props.top || ''};
  z-index: ${props => props.zIndex || ''};
  box-shadow: ${({ scrollNav, position }) =>
    position === 'sticky' && scrollNav
      ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
      : ''};

  width: 100%;
  background: ${props => props.bg || 'inherit'};
  margin: ${props => props.margin || 'inherit'};

  @media screen and (max-width: ${midBp}) {
    margin: ${props => props.mdBpMargin || 'inherit'};
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDir || ''};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};

  background: ${props => props.bg || 'inherit'};

  max-width: ${props => props.maxWidth || '1440px'};
  width: 100%;
  margin: 0 auto;
  padding: ${props => props.paddingY || '24px'} 53px;
  padding: ${props => props.padding || ''};

  @media screen and (max-width: ${largeBp}) {
    flex-direction: ${props => props.lgBpFlexDir || ''};
  }

  @media screen and (max-width: ${midBp}) {
    flex-direction: ${props => props.smBpFlexDir || ''};
    padding: ${props => props.smBpPaddingY || 'inherit'} 24px;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: ${props => props.smBpPaddingY || '24px'} 24px;
    padding: ${props => props.smBpPadding || ''};
  }
`;

export const CommonHeading = styled.h1`
  max-width: ${props => props.maxWidth || 'inherit'};
  margin: ${props => props.margin || 0};

  font-family: 'Nexa Bold';
  font-weight: 700;
  font-size: 3.5em;
  line-height: 90.5%;
  text-align: center;
  letter-spacing: -0.005em;

  @media screen and (max-width: ${largeBp}) {
    margin: ${props => props.lgBpMargin || ''};

    font-size: 3em;
  }

  @media screen and (max-width: ${midBp}) {
    margin: ${props => props.mdBpMargin || ''};

    font-size: 2.5em;
  }

  @media screen and (max-width: ${smallBp}) {
    margin: ${props => props.smBpMargin || ''};

    font-size: 1.5em;
  }
`;

export const BoldSpan = styled.span`
  color: ${props => props.fg || 'inherit'};
  font-weight: ${props => props.fontWeight || 700};
`;

export const GradientBar = styled.div`
  background: ${props => props.theme.gradient};

  width: 100%;
  height: ${props => props.height || '13px'};
  margin: ${props => props.margin || '0'};

  @media screen and (max-width: ${midBp}) {
    height: ${props => props.mdBpHeight || '13px'};
    margin: ${props => props.mdBpMargin || '0'};
  }
`;
