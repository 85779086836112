import styled from 'styled-components';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const LogoContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${largeBp}) {
    justify-content: center;
    align-items: center;

    margin-bottom: 26px;
    padding-bottom: 20px;
    border-bottom: 2px solid ${props => props.theme.color.black};
  }

  @media screen and (max-width: ${smallBp}) {
    margin-bottom: 13px;
    padding-bottom: 10px;
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;

  max-width: 418px;
  width: 100%;
  min-width: 200px;

  margin-right: 71px;

  @media screen and (max-width: ${largeBp}) {
    max-width: 150px;
    min-width: unset;
    margin-right: 24px;
  }

  @media screen and (max-width: ${midBp}) {
    max-width: 100px;
    margin-right: 16px;
  }

  @media screen and (max-width: ${smallBp}) {
    max-width: 50px;
    margin-right: 13px;
  }
`;

export const Logo = styled.img`
  width: 100%;
`;

export const Heading = styled.h1`
  padding: 0 64px 0 27px;
  border-right: 2px solid ${props => props.theme.color.black};

  transform: rotate(180deg);

  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  font-size: 4.5625em;
  line-height: 73px;
  text-align: center;
  letter-spacing: -0.005em;

  @media screen and (max-width: ${largeBp}) {
    padding: 0;
    border-right: none;

    transform: unset;

    writing-mode: horizontal-tb;
    text-orientation: upright;
  }

  @media screen and (max-width: ${midBp}) {
    font-size: 3.25em;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 2em;
  }
`;

export const TextWrap = styled.div`
  flex: 1;

  min-width: 400px;

  @media screen and (max-width: ${largeBp}) {
    min-width: unset;
  }
`;

export const AboutP = styled.p`
  margin-bottom: 29.57px;

  font-weight: 500;
  font-size: 1.1875em;
  line-height: 165.02%;
  letter-spacing: -0.01em;

  :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${largeBp}) {
    font-size: 1.125em;
  }

  @media screen and (max-width: ${midBp}) {
    font-size: 1em;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875em;
  }
`;
