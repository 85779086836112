import React, { useState, useEffect } from 'react';
import { CommonHeading, Container, Wrap } from '../others.style';
import {
  Text,
  SubText,
  FormWrap,
  Form,
  ErrorMessage,
  InputWrap,
  Input,
  Button,
  Loader,
} from './newsletter.style';

import { initializeApp } from 'firebase/app';
import {
  addDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Add a new document in collection "userEmails"
const addEmailToDb = async email => {
  await addDoc(collection(db, 'userEmails'), {
    email: email,
    createdAt: serverTimestamp(),
  });
};

const Newsletter = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  const ModalAlert = withReactContent(Swal);

  // check if email is valid
  const validateEmail = email => {
    if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  // isNewEmail checks if email is already in the database
  const isNewEmail = async email => {
    let existingEmailCount = 0;

    // Create a reference to the emails collection
    const userEmailsRef = collection(db, 'userEmails');

    // Create a query against the collection.
    const q = query(userEmailsRef, where('email', '==', email));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(doc => {
      existingEmailCount++;
      // doc.data() is never undefined for query doc snapshots
    });

    const isNewEmailAddress = existingEmailCount === 0 ? true : false;
    return isNewEmailAddress;
  };

  // submit email when enter key is pressed
  const handleKeypress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(e, emailAddress);
    }
  };

  const handleSubmit = (e, email) => {
    if (validateEmail(email)) {
      setLoading(true);
      isNewEmail(email)
        .then(res => {
          if (res) {
            addEmailToDb(email);
            ModalAlert.fire({
              icon: 'success',
              title: 'You have been added to the waiting list!',
              confirmButtonColor: '#ee0077',
            });

            setEmailAddress('');
            setLoading(false);

            return;
          }
          ModalAlert.fire({
            title: 'You are already subscribed!',
            confirmButtonColor: '#ee0077',
          });
          setLoading(false);

          return;
        })
        .catch(err => console.log(err));
      return;
    }

    setEmailError('Please enter a valid email address!');
    return;
  };

  useEffect(() => {
    if (document.location.hash === '#join') {
      document.location.hash = '#join-us';
    }
  }, []);

  return (
    <Container margin="50px 0 0 0" mdBpMargin="50px 0 0 0" id="join-us">
      <Wrap flexDir="column">
        <Text>
          <CommonHeading>Join Our Community</CommonHeading>
          <SubText>Notify me when it launches</SubText>
        </Text>
        <FormWrap>
          <ErrorMessage show={emailError !== '' ? true : false}>
            {emailError}
          </ErrorMessage>
          <Form>
            <InputWrap>
              <Input
                type="email"
                placeholder="Type your email"
                value={emailAddress}
                onChange={e => {
                  setEmailError('');
                  setEmailAddress(e.target.value);
                }}
                onKeyDown={handleKeypress}
              />
            </InputWrap>
            <Button
              type="button"
              aria-label="submit email"
              onClick={e => handleSubmit(e, emailAddress)}
            >
              {loading ? <Loader /> : 'Notify'}
            </Button>
          </Form>
        </FormWrap>
      </Wrap>
    </Container>
  );
};

export default Newsletter;
