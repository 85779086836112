import React, { useState } from 'react';
import { CommonHeading, Container, Wrap } from '../others.style';
import { GridWrap, ExpandButton, Grid, Tile, TweetImg } from './pointers.style';

import tweet01 from '../../assets/tweets/tweet01.webp';
import tweet02 from '../../assets/tweets/tweet02.webp';
import tweet03 from '../../assets/tweets/tweet03.webp';
import tweet04 from '../../assets/tweets/tweet04.webp';
import tweet05 from '../../assets/tweets/tweet05.webp';
import tweet06 from '../../assets/tweets/tweet06.webp';
import tweet07 from '../../assets/tweets/tweet07.webp';
import tweet08 from '../../assets/tweets/tweet08.webp';
import tweet09 from '../../assets/tweets/tweet09.webp';
import tweet10 from '../../assets/tweets/tweet10.webp';
import tweet11 from '../../assets/tweets/tweet11.webp';
import tweet12 from '../../assets/tweets/tweet12.webp';

const Pointers = () => {
  const [limit, setLimit] = useState(6);

  const tweets = [
    tweet01,
    tweet02,
    tweet03,
    tweet04,
    tweet05,
    tweet06,
    tweet07,
    tweet08,
    tweet09,
    tweet10,
    tweet11,
    tweet12
  ];

  return (
    <Container>
      <Wrap flexDir="column" paddingY="60px">
        <CommonHeading maxWidth="714px">
          We Heard Your Pain Points and are Bringing a Solution
        </CommonHeading>
        <GridWrap isOpen={limit === 12}>
          <Grid>
            {tweets.slice(0, limit).map((tweet, index) => {
              return (
                <Tile key={index}>
                  <TweetImg src={tweet} alt="image of tweet" />
                </Tile>
              );
            })}
          </Grid>
          <ExpandButton
            type="button"
            onClick={() => (limit === 6 ? setLimit(12) : setLimit(6))}
            isOpen={limit === 12}
            aria-label="expand tweets"
          />
        </GridWrap>
      </Wrap>
    </Container>
  );
};

export default Pointers;
