import styled from 'styled-components';

import caret from '../../assets/caret_up.svg';

const largeBp = props => props.theme.breakpoint.large;
const midBp = props => props.theme.breakpoint.medium;
const smallBp = props => props.theme.breakpoint.small;

export const GridWrap = styled.div`
  position: relative;

  background: linear-gradient(
    352.88deg,
    rgba(238, 0, 119, 0.48) 32.25%,
    rgba(132, 92, 251, 0.16) 70.23%,
    rgba(132, 92, 251, 0.08) 88.38%
  );

  width: 100%;
  margin-top: 162px;
  padding: 48px 48px ${({ isOpen }) => (isOpen ? '120px' : '64px')} 48px;
  border-radius: 20px;

  @media screen and (max-width: ${largeBp}) {
    padding: 40px;
  }

  @media screen and (max-width: ${midBp}) {
    margin-top: 80px;
    padding: 32px;

    overflow-y: auto;
    height: 80vh;
  }

  @media screen and (max-width: ${smallBp}) {
    margin-top: 40px;
    padding: 22px;

    height: 70vh;
  }
`;

export const ExpandButton = styled.button`
  position: absolute;
  bottom: 0;

  right: 50%;
  margin-right: -37px;
  margin-bottom: -37px;

  width: 74px;
  height: 74px;
  border: none;

  background-image: url(${caret});
  background-color: transparent;

  transform: ${props => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.3s ease-in-out;

  @media screen and (max-width: ${midBp}) {
    display: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 26px;
  column-gap: 30px;
  grid-auto-flow: row;

  margin-top: -144px;

  @media screen and (max-width: ${largeBp}) {
    row-gap: 22px;
    column-gap: 26px;

    margin-top: -120px;
  }

  @media screen and (max-width: ${midBp}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 18px;
    column-gap: 21px;

    margin-top: 0;
  }

  @media screen and (max-width: ${smallBp}) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 12px;

    margin-top: 0;
  }
`;

export const Tile = styled.div`
  background: ${props => props.theme.color.white};

  padding: 44px 14px;
  box-shadow: 12px 39px 55px rgba(51, 95, 103, 0.16);
  border-radius: 15px;

  @media screen and (max-width: ${midBp}) {
    padding: 30px 10px;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: 22px 7px;
  }
`;

export const TweetImg = styled.img`
  max-width: 364px;
  width: 100%;
  height: auto;
`;
